/* Slider */
.slick-loading .slick-list { background: #fff url('../img/ajax-loader.gif') center center no-repeat; }
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 50px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 9;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before { opacity: .25; }

.slick-prev:before,
.slick-next:before {
    font-family: Flaticon;
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev { left: 0; }
[dir='rtl'] .slick-prev  { right: 0; left: auto; }
.slick-prev:before { content: "\f143"; }
[dir='rtl'] .slick-prev:before { content: "\f14a"; }

.slick-next { right: 0; }
[dir='rtl'] .slick-next { right: auto; left: 0; }
.slick-next:before { content: "\f14a"; }
[dir='rtl'] .slick-next:before { content: "\f143"; }

/* Dots */
.slick-dotted.slick-slider { margin-bottom: 0; }
.slick-dots {
    position: absolute;
    bottom: -25px;
    left: 0;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus { outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before { opacity: 1; }
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before { opacity: .75; color: black; }
	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/03/2018 20:56
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../webfonts/Flaticon.eot");
  src: url("../webfonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../webfonts/Flaticon.woff") format("woff"),
       url("../webfonts/Flaticon.ttf") format("truetype"),
       url("../webfonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../webfonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-style: normal;
}

.flaticon-quality:before { content: "\f100"; }
.flaticon-bar-chart:before { content: "\f101"; }
.flaticon-pie-chart:before { content: "\f102"; }
.flaticon-flask:before { content: "\f103"; }
.flaticon-menu-2:before { content: "\f104"; }
.flaticon-color-wheel-black-and-white:before { content: "\f105"; }
.flaticon-ellipsis:before { content: "\f106"; }
.flaticon-show-more-button-with-three-dots:before { content: "\f107"; }
.flaticon-settings:before { content: "\f108"; }
.flaticon-search-1:before { content: "\f109"; }
.flaticon-home:before { content: "\f10a"; }
.flaticon-discover-paying-card:before { content: "\f10b"; }
.flaticon-mastercard:before { content: "\f10c"; }
.flaticon-visa-pay-logo:before { content: "\f10d"; }
.flaticon-zoom-out:before { content: "\f10e"; }
.flaticon-zoom-in:before { content: "\f10f"; }
.flaticon-view-list-button:before { content: "\f110"; }
.flaticon-view-list:before { content: "\f111"; }
.flaticon-hashtag-symbol:before { content: "\f112"; }
.flaticon-hashtag:before { content: "\f113"; }
.flaticon-folders:before { content: "\f114"; }
.flaticon-internet:before { content: "\f115"; }
.flaticon-checklist:before { content: "\f116"; }
.flaticon-box:before { content: "\f117"; }
.flaticon-credit-card-1:before { content: "\f118"; }
.flaticon-calendar:before { content: "\f119"; }
.flaticon-wallet:before { content: "\f11a"; }
.flaticon-avatar-inside-a-circle:before { content: "\f11b"; }
.flaticon-mail:before { content: "\f11c"; }
.flaticon-smartphone-call:before { content: "\f11d"; }
.flaticon-placeholder:before { content: "\f11e"; }
.flaticon-map-location:before { content: "\f11f"; }
.flaticon-eye:before { content: "\f120"; }
.flaticon-armchair:before { content: "\f121"; }
.flaticon-soccer-ball-variant:before { content: "\f122"; }
.flaticon-high-heels:before { content: "\f123"; }
.flaticon-shirt:before { content: "\f124"; }
.flaticon-television:before { content: "\f125"; }
.flaticon-teddy-bear:before { content: "\f126"; }
.flaticon-favorite-heart-button:before { content: "\f127"; }
.flaticon-do-not-disturb-rounded-sign:before { content: "\f128"; }
.flaticon-searching-magnifying-glass:before { content: "\f129"; }
.flaticon-drop-up-arrow:before { content: "\f12a"; }
.flaticon-close-button:before { content: "\f12b"; }
.flaticon-clock-with-white-face:before { content: "\f12c"; }
.flaticon-locked-padlock:before { content: "\f12d"; }
.flaticon-arrow-pointing-to-up:before { content: "\f12e"; }
.flaticon-arrow-pointing-to-down:before { content: "\f12f"; }
.flaticon-null:before { content: "\f130"; }
.flaticon-arrow-pointing-to-right:before { content: "\f131"; }
.flaticon-shopping-purse-icon:before { content: "\f132"; }
.flaticon-support-speech-bubble:before { content: "\f133"; }
.flaticon-credit-cards-payment:before { content: "\f134"; }
.flaticon-new-user:before { content: "\f135"; }
.flaticon-man-user:before { content: "\f136"; }
.flaticon-gift-card:before { content: "\f137"; }
.flaticon-truck:before { content: "\f138"; }
.flaticon-sort-1:before { content: "\f139"; }
.flaticon-gift-2:before { content: "\f13a"; }
.flaticon-arrow-pointing-to-left:before { content: "\f13b"; }
.flaticon-undo-arrow:before { content: "\f13c"; }
.flaticon-move-arrows:before { content: "\f13d"; }
.flaticon-redo-arrow:before { content: "\f13e"; }
.flaticon-old-handphone:before { content: "\f13f"; }
.flaticon-logo:before { content: "\f140"; }
.flaticon-arrows-4:before { content: "\f141"; }
.flaticon-dollar-symbol:before { content: "\f142"; }
.flaticon-arrows-3:before { content: "\f143"; }
.flaticon-great-britain-pound:before { content: "\f144"; }
.flaticon-euro-currency-symbol:before { content: "\f145"; }
.flaticon-mark-as-favorite-star:before { content: "\f146"; }
.flaticon-refresh-page-option:before { content: "\f147"; }
.flaticon-sort-by-alphabet:before { content: "\f148"; }
.flaticon-angle-arrow-down:before { content: "\f149"; }
.flaticon-arrows-2:before { content: "\f14a"; }
.flaticon-car-compact:before { content: "\f14b"; }
.flaticon-shopping-cart-of-checkered-design:before { content: "\f14c"; }
.flaticon-right-quote-sign:before { content: "\f14d"; }
.flaticon-left-quote:before { content: "\f14e"; }
.flaticon-star-social-favorite-middle-full:before { content: "\f14f"; }
.flaticon-email-filled-closed-envelope:before { content: "\f150"; }
.flaticon-heart-shape-outline:before { content: "\f151"; }
.flaticon-aeroplane:before { content: "\f152"; }
.flaticon-support:before { content: "\f153"; }
.flaticon-telemarketer:before { content: "\f154"; }
.flaticon-danger:before { content: "\f155"; }
.flaticon-dress:before { content: "\f156"; }
.flaticon-tap:before { content: "\f157"; }
.flaticon-cash-money:before { content: "\f158"; }
.flaticon-credit-card:before { content: "\f159"; }
.flaticon-down-arrow:before { content: "\f15a"; }
.flaticon-sort:before { content: "\f15b"; }
.flaticon-menu-1:before { content: "\f15c"; }
.flaticon-menu:before { content: "\f15d"; }
.flaticon-notification-1:before { content: "\f15e"; }
.flaticon-notification:before { content: "\f15f"; }
.flaticon-diamond-ring:before { content: "\f160"; }
.flaticon-jeans:before { content: "\f161"; }
.flaticon-send-mail:before { content: "\f162"; }
.flaticon-arrows-1:before { content: "\f163"; }
.flaticon-arrows:before { content: "\f164"; }
.flaticon-favorite:before { content: "\f165"; }
.flaticon-shopping-cart:before { content: "\f166"; }
.flaticon-checked:before { content: "\f167"; }
.flaticon-diamond-1:before { content: "\f168"; }
.flaticon-interface:before { content: "\f169"; }
.flaticon-play-button-1:before { content: "\f16a"; }
.flaticon-umbrella-1:before { content: "\f16b"; }
.flaticon-search:before { content: "\f16c"; }
.flaticon-done:before { content: "\f16d"; }
.flaticon-add:before { content: "\f16e"; }
.flaticon-substract:before { content: "\f16f"; }
.flaticon-share:before { content: "\f170"; }
.flaticon-umbrella:before { content: "\f171"; }
.flaticon-diamond:before { content: "\f172"; }
.flaticon-information:before { content: "\f173"; }
.flaticon-play-button:before { content: "\f174"; }
.flaticon-question:before { content: "\f175"; }